<template>
  <div class="page_content_wrap">
    <div class="expenses_slider_wrap py-5">
      <div class="container">
        <div class="expenses_slider_box" v-if="!isLoading">
          <h2 class="text-dark mb-2">
            What is your estimated household income now, pre-retirement?
          </h2>
          <p class="text-dark font-size-lg mb-5">
            Use the sliders to estimate your income.
          </p>
          <form action="" @submit.prevent="submitWizard">
            <div
              class="form-group range_slider_wrap mb-5"
              :key="index"
              v-for="(income, index) in incomes"
            >

						<div class="edit-and-print">
							<div class="row align-items-center">
									<div class="col-12 col-sm-6 h6 text-dark d-flex align-items-center">
										<input
                      v-if="index == currentEdit"
                      type="text"
                      class="form-control"
                      v-model="income.title"
                    />
                    <span v-if="index !== currentEdit">{{ income.title }}</span>
									</div>
									<div class="col-8 col-sm-4 pr-0">
											<div class="d-flex align-items-center">
													<h4 class="m-0 text-primary mr-1">$</h4>
													<h4 class="m-0 text-primary d-flex align-items-center">
															<!-- <input type="number" placeholder="10000" min="0" max="500000" class="form-control mr-1"> -->
															<input
																type="number"
																class="form-control mr-1"
																placeholder="10000"
																min="0"
																max="500000"
																v-model="income.value"
															/>
															<small class="text-dark font-size-sm">/annual</small>
													</h4>
											</div>
									</div>
									<div class="col-4 col-sm-2">
											<div class="form_repeater_action">
													<a
														href="javscript:void(0);"
														class="border border-gray-300"
														@click.prevent="enableEdit(index)"
														><i class="fal fa-edit"></i
													></a>
													<a
														href="javscript:void(0);"
														class="border border-gray-300"
														@click.prevent="removeIncome({ index, income })"
														><i class="fal fa-trash-alt"></i
													></a>
													
											</div>
									</div>
							</div>
							</div>

              <!-- <div class="edit-and-print align-items-center">
                <div class="row">
                  <div class="col-6 h6 text-dark d-flex align-items-center">
                    <input
                      v-if="index == currentEdit"
                      type="text"
                      class="form-control"
                      v-model="income.title"
                    />
                    <span v-if="index !== currentEdit">{{ income.title }}</span>
                  </div>
                  <div class="col-6">
                    <div class="d-flex justify-content-end align-items-center">
                      <h4 class="m-0 text-primary mr-1">$</h4>
                      <h4 class="m-0 text-primary d-flex align-items-center">
                        <input
                          type="number"
                          class="form-control mr-1"
                          placeholder="10000"
                          min="0"
                          max="500000"
                          v-model="income.value"
                        />
                        <small class="text-dark font-size-sm"
                          >/{{ income.timeFrame }}</small
                        >
                      </h4>
                    </div>
                  </div>
                </div>
              </div> -->

              <r-slider
                :min="0"
                :max="500000"
                :step="10"
                v-model.number="income.value"
              ></r-slider>

            </div>

            <div class="form-group mb-5 form_another_item">
              <button
                type="button"
                class="btn btn-outline-info"
                @click="enableAddNewBtn"
              >
                <i
                  class="fas fa-plus border border-gray-300 mr-1"
                  v-if="!enableAddNew"
                ></i>
                <i
                  class="fas fa-minus border border-gray-300 mr-1"
                  v-if="enableAddNew"
                ></i>
                {{ enableAddNew ? "Cancel" : "Add Another Item" }}
              </button>
            </div>

            <div class="form-group mb-5 form_repeater_item" v-if="enableAddNew">
              <div class="row">
                <div class="col-sm-12 col-md-8 mb-3 mb-md-0">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Type of Income"
                    v-model="newIncomeTitle"
                  />
                </div>
                <div class="col-sm-12 col-md-4">
                  <button
                    type="button"
                    class="btn btn-outline-primary btn-block"
                    @click="addNewIncome"
                    :disabled="newIncomeTitle === ''"
                  >
                    ADD
                  </button>
                </div>
              </div>
            </div>

            <div class="btn_continue_wrap">
              <button
                type="submit"
                class="btn btn-primary btn-lg btn-block mt-4"
                :disabled="isSubmitting"
                :class="{ 'btn-load': isSubmitting }"
              >
                CONTINUE <i class="fas fa-arrow-right pl-1"></i>
              </button>
            </div>
          </form>
        </div>

        <div class="loader" v-if="isLoading"></div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { getContact, updateContact } from "@/utils/firebase";
import { HUBSPOT_DISPOSABLE_INCOME_LIST_KEY } from "@/utils/constants";
import { parse } from "query-string";

export default {
  name: "Screen9",
  data() {
    return {
      currentEdit: null,
      isLoading: true,
      isSubmitting: false,
      errors: [],
      enableAddNew: false,
      newIncomeTitle: "",
      hubspotQuestionId: HUBSPOT_DISPOSABLE_INCOME_LIST_KEY,
    };
  },
  computed: {
    ...mapGetters({
      getUser: "getUser",
      getUserId: "getUserId"
    }),
    ...mapGetters("wizard", {
      incomes: "getDisposableIncomes",
    }),
  },
  mounted() {
    this.isLoading = true;
    const uu = this.getUser || {}
    console.log({uu})
    const contactId = uu.email

    getContact(contactId)
      .then((response) => {
        let prop = response.data.properties;

        console.log("prop");
        console.log(prop);
        console.log(this.hubspotQuestionId);

        let disposable_income_list = prop[this.hubspotQuestionId]
          ? JSON.parse(prop[this.hubspotQuestionId])
          : [];

        const parsedData = disposable_income_list.length
          ? disposable_income_list
          : [{ title: "Income", timeFrame: "annual", value: 0 }];

        console.log("response.data.properties");
        console.log(parsedData);
        this.SET_DISPOSABLE_INCOME(parsedData);

        this.isLoading = false;
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
        this.isLoading = false;
      });
  },
  methods: {
    ...mapActions("wizard", {
      updateProgress: "updateProgress",
    }),
    ...mapMutations("wizard", [
      "SET_DISPOSABLE_INCOME",
      "ADD_NEW_DISPOSABLE_INCOME",
      "REMOVE_DISPOSABLE_INCOME",
    ]),
    enableAddNewBtn() {
      this.newIncomeTitle = "";
      this.enableAddNew = !this.enableAddNew;
    },
    enableEdit(index) {
      this.currentEdit = index;
    },
    removeIncome(obj) {
      this.REMOVE_DISPOSABLE_INCOME(obj);
    },
    addNewIncome() {
      console.log(this.newIncomeTitle.replace(/\$+/g, ""));
      if (this.newIncomeTitle != "") {
        let obj = {
          title: this.newIncomeTitle.replace(/\$+/g, ""),
          timeFrame: "annual",
          value: 0,
        };
        this.ADD_NEW_DISPOSABLE_INCOME(obj);
        this.newIncomeTitle = "";
        this.enableAddNew = false;
      }
    },
    submitWizard(e) {
      this.isSubmitting = true;
      console.log("sybmit")
    const contactId = "kyle@dataskeptic.com"

      if (!this.validateDuplicate()) {
        console.log("here!")
        console.log(this.incomes);
        console.log(this.hubspotQuestionId);
        const hubspotQuestionId = this.hubspotQuestionId
        updateContact(contactId, {
          hubspotQuestionId: this.incomes
        })
          .then((response) => {
            const queryParams = parse(location.search);
            if (queryParams["from-review"]) {
              this.$router.push({ name: "risk.review" });
            } else {
              let userObj = {
                riskLink: "risk.fixedIncome",
              };
              this.updateProgress({ step: 1, data: userObj });
              this.$router.push({ name: "risk.fixedIncome" });
            }
            //   this.updateProgress();
          })
          .catch((error) => {
            console.log(error);
            this.isSubmitting = false;
            alert("Something went wrong!");
          });
      } else {
        alert("Inome Name should be unique");
      }

      e.preventDefault();
    },
    validateDuplicate() {
      var valueArr = this.incomes.map(function (item) {
        return item.title;
      });
      var isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) != idx;
      });
      console.log(isDuplicate);
    },
  },
};
</script>
